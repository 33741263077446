import React, {useState} from 'react';
import PropTypes from 'prop-types';

const FLASH_TIMEOUT = 20000;

export const FlashMessageContext = React.createContext({
  messageText: '',
  messageClass: '',
  show: false,
  showMessage: () => {}
});

export const FlashMessageContextProvider = props => {

  /*********************************************
   * INITIALIZATION
   *********************************************/
  const [messageText, setMessageText] = useState(null);
  const [messageClass, setMessageClass] = useState(null);
  const [show, setShow] = useState(false);


  /*********************************************
   * EVENT HANDLERS
   *********************************************/

  const messageHandler = (messageText, messageClass) => {
    // Dismiss the message after a given time
    setTimeout(() => {
      setMessageText(null);
      setMessageClass(null);
      setShow(false);
    }, FLASH_TIMEOUT);
    setMessageText(messageText);
    setMessageClass(messageClass);
    setShow(!!messageText);
  };


  /*********************************************
   * CONTEXT PROVIDER
   *********************************************/

  return (
    <FlashMessageContext.Provider value={{
      messageText: messageText,
      messageClass: messageClass,
      show: show,
      showMessage: messageHandler
    }}>
      {props.children}
    </FlashMessageContext.Provider>
  )
};

FlashMessageContextProvider.propTypes = {
  children: PropTypes.node
}
