import React from 'react';
import PropTypes from 'prop-types';

export const Required = ({ children }) => {
  return <><span style={{ color: 'red' }}>*&nbsp;</span>{children}</>
}

Required.propTypes = {
  children: PropTypes.node,
}
