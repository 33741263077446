import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useAuth0 } from '../react-auth0-wrapper';
import { getSchoolIds } from './FetchData';
import { FlashMessageContext } from '../context/flash-message';
import UserManagement from './UserManagement';
import StudentManagement from './StudentManagement';
import AssessmentManagement from './AssessmentManagement';
import api from '../api';
import '../styles/admin.css';
import {useCookies} from 'react-cookie';


const AdminPage = () => {
  const DEFAULT_TAB = 'users'
  const PAUSED_ASSESSMENT_STATUS = 'paused'
  const { isAdmin, getTokenSilently, user } = useAuth0();

  const [students, setStudents] = useState([]);
  const [adminUsers, setAdminUsers] = useState([]);
  const [assessmentId, setAssessmentId] = useState();
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [assessmentPaused, setAssessmentPaused] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [assessmentStatusLoading, setAssessmentStatusLoading] = useState(false);
  const [tab, setTab] = useState(DEFAULT_TAB)
  const history = useHistory();
  const flashMessage = useContext(FlashMessageContext);
  const [cookie, setCookie] = useCookies(['lastTab']);
  const [key, setKey] = useState('users');

  const loadStudents = async () => {
    setKey('students')
    const token = await getTokenSilently();
    const schoolIds = await getSchoolIds(token)
    const firstSchool = schoolIds.split(",")[0]

    api.get(`/schools/${firstSchool}/latest-assessment`)
      .then((data) => {
        if (data && data.id) {
          setAssessmentId(data.id);
          api.get(`/assessments/${data.id}/students`)
            .then(
              data => {
                if (data) {
                  setStudents(data)
                } else {
                  flashMessage.showMessage("No student data available.")
                }
              }
            ).catch(
            error => console.error(error)
          )
        } else {
            flashMessage.showMessage("No assessment data available yet.", "success")
        }
      }
      ).catch((error) => {
        console.error(error)
      })
  }

  const loadAssessmentStatus = async () => {
    setKey('assessment')
    setAssessmentStatusLoading(true);
    const token = await getTokenSilently();
    const schoolIds = await getSchoolIds(token)
    const firstSchool = schoolIds.split(",")[0]

    api.get(`/schools/${firstSchool}/latest-assessment`)
      .then((data) => {
        if (data && data.id) {
          api.get(`/assessments/${data.id}/status`)
            .then((data) => {
              if (data && data.status === PAUSED_ASSESSMENT_STATUS) {
                setAssessmentPaused(true)
                setAssessmentStatusLoading(false);
              }
            })
            .catch((error) => {
              console.error(error)
              setAssessmentStatusLoading(false);
            })
        }
      })
      .catch(error => {
        console.error(error)
        setAssessmentStatusLoading(false);
      })
  }

  const pauseOrUnpauseAssessment = async () => {
    const resultAction = assessmentPaused ? 'resumed' : 'paused'
    const status = assessmentPaused ? 'running' : 'paused'

    const token = await getTokenSilently();
    const schoolIds = await getSchoolIds(token)
    const firstSchool = schoolIds.split(",")[0]

    api.get(`/schools/${firstSchool}/latest-assessment`)
      .then((data) => {
        if (data && data.id) {
          api.post(`/assessments/${data.id}/status`, { status })
            .then((data) => {
              if (data) {
                if (data.status === PAUSED_ASSESSMENT_STATUS) {
                  setAssessmentPaused(true)
                } else {
                  setAssessmentPaused(false)
                }

                window.alert(`Assessment ${resultAction}`)
              }
            })
            .catch((error) => console.error(error))
        }
      })
      .catch(error => console.error(error))
  }

  const addUser = (user) => {
    let updated;
    const existingIndex = adminUsers.findIndex(u => u.user_id === user.user_id)
    if (existingIndex > -1) {
      updated = [
        ...adminUsers.slice(0, existingIndex),
        user,
        ...adminUsers.slice(existingIndex + 1)
      ]
    } else {
      updated = [...adminUsers, user]
    }

    setAdminUsers(updated)
  }

  const removeUser = (user) => {
    const existingIndex = adminUsers.findIndex(u => u.user_id === user.user_id)
    const updated = [
      ...adminUsers.slice(0, existingIndex),
      ...adminUsers.slice(existingIndex + 1)
    ]
    setAdminUsers(updated)
  }

  const loadUsers = async () => {
    setKey('users')
    const token = await getTokenSilently();
    const schoolIds = await getSchoolIds(token);

    setIsUserLoading(true)
    api.get('/admin/users', {
      'X-School-Ids': schoolIds,
      'X-Originator-Email': user.email
    })
      .then(data => {
        setAdminUsers(data);
        setIsUserLoading(false);
      })
      .catch(error => {
        console.log(error);
        setIsUserLoading(false);
      })
  }

  const autoAssignExams = async () => {
    const token = await getTokenSilently();
    const schoolIds = await getSchoolIds(token);

    api.post('/assign-exams', {}, {
      'X-School-Ids': schoolIds
    })
      .then(result => {
        if (result) {
          window.alert("Exams assigned.")
          switchTab('students')
        } else {
          flashMessage.showMessage('Auto-assign failed.')
        }
      })
      .catch(error => {
        console.error(error)
        flashMessage.showMessage('Auto-assign failed.')
      })
  }

  const closeAndScore = async () => {
    const token = await getTokenSilently();
    const schoolIds = await getSchoolIds(token);

    api.post('/close-and-score', {}, {
      'X-School-Ids': schoolIds
    })
      .then(result => {
        if (result) {
          window.alert("Generating scores and associated reports.")
          history.push('/reports')
        } else {
          flashMessage.showMessage('Scoring failed.')
        }
      })
      .catch(error => {
        console.error(error)
        flashMessage.showMessage('Scoring failed.')
      })
  }

  const switchTab = (tab) => {
    loadTabData(tab)
  }

  const loadTabData = (tab) => {
    switch (tab) {
      case 'users':
        loadUsers()
        break;
      case 'students':
        loadStudents()
        break;
      case 'assessment':
        loadAssessmentStatus()
        break;
      default:
        setTab(DEFAULT_TAB)
        loadUsers()
        break;
    }
    setCookie('lastTab', tab);
  }

  useEffect(() => {
    const ensurePermissions = async () => {
      if (!isAdmin()) {
        history.push('/');
      } else {
        const lastTab = cookie['lastTab'];
        if (lastTab) {
          loadTabData(lastTab);
        } else {
            loadTabData(tab);
        }
      }
    }

    ensurePermissions();
    // eslint-disable-next-line
  }, [isAdmin, history, tab]);

  return (
    <section id="admin">
      <Tabs activeKey={key} onSelect={(tab) => switchTab(tab)} className="mb-3">
        <Tab title="Manage Admin Users" eventKey="users" id="admin-stc">
          <UserManagement
            users={adminUsers}
            addUser={addUser}
            removeUser={removeUser}
            isLoading={isUserLoading}
            setIsLoading={setIsUserLoading}
            loadUsers={loadUsers}
          />
        </Tab>
        <Tab title="Manage Students" eventKey="students" id="admin-students">
          <StudentManagement
            assessmentId={assessmentId}
            students={students}
            setStudents={setStudents}
          />
        </Tab>
        <Tab title="Manage Assessment" eventKey="assessment" id="admin-assessment">
          <AssessmentManagement
            assessmentPaused={assessmentPaused}
            autoAssignExams={autoAssignExams}
            closeAndScore={closeAndScore}
            pauseOrUnpauseAssessment={pauseOrUnpauseAssessment}
          />
        </Tab>
      </Tabs>
    </section>
  )
}

export default AdminPage
