import React, { useContext, useState } from 'react';

import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { Required } from './common';
import { useAuth0 } from '../react-auth0-wrapper';
import { getSchoolIds } from './FetchData';
import { FlashMessageContext } from '../context/flash-message';
import api from '../api';
import PropTypes from 'prop-types';

const UserManagement = ({ users, addUser, removeUser, isLoading, setIsLoading, loadUsers }) => {
  const DEFAULT_FORM_DATA = {
    email: '',
    name: '',
    isAdmin: false
  }
  const { user: auth0User, getTokenSilently } = useAuth0();
  const [formData, setFormData] = useState(DEFAULT_FORM_DATA)
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortColumn, setSortColumn] = useState('email');
  const flashMessageContext = useContext(FlashMessageContext);

  if (isLoading) {
    return <Spinner />
  }

  const remove = async (event, user) => {
    event.preventDefault()
    const ans = window.confirm(`Are you sure you want to remove ${user.email}?`)
    if (ans) {
      const token = await getTokenSilently();
      const schoolIds = await getSchoolIds(token);
      const endpoint = `/admin/users`

      setIsLoading(true)
      api.delete(
        endpoint,
        { email: user.email },
        { 'X-School-Ids': schoolIds }
      ).then(response => {
        switch (response.status) {
          case 400:
          case 500:
            flashMessageContext.showMessage("Delete failed.")
            break;
          default:
            console.log(`Response Status ${response.status}`)
        }
        removeUser(user)
        setIsLoading(false)
        return response.text()
      }).then(text => console.log(text))
      clearUser()
    }
  }

  const edit = (user) => {
    updateRoleField(user.is_portal_admin)
    setFormData({
      email: user.email,
      name: user.name,
      isAdmin: user.is_portal_admin
    })
  }

  const updateFormField = (field, value) => {
    if (field === 'email' && value) {
      value = value.toLowerCase()
    }
    const updated = { ...formData, [field]: value }
    setFormData(updated)
  }

  const updateRoleField = (value) => {
    const radios = document.getElementsByName('role-radio')
    radios.forEach(radio => {
      radio.checked = false
    })

    if (value) {
      const admin = document.getElementById('role-radio-admin')
      admin.checked = true
      setFormData({ ...formData, isAdmin: true })
    } else {
      const proctor = document.getElementById('role-radio-proctor')
      proctor.checked = true
      setFormData({ ...formData, isAdmin: false })
    }
  }

  const clearUser = () => {
    setFormData(DEFAULT_FORM_DATA)
  }

  const formInvalid = () => !formData.email || !formData.name

  const saveUser = async () => {
    if (formInvalid()) {
      flashMessageContext.showMessage("Please fill in required fields before saving.")
    } else {
      const token = await getTokenSilently();
      const schoolIds = await getSchoolIds(token);
      const endpoint = `/admin/users`

      setIsLoading(true)
      api.post(
        endpoint,
        formData,
        { 'X-School-Ids': schoolIds }
      )
        .then(updatedUser => {
          setIsLoading(false)
          addUser(updatedUser)
          loadUsers()
        })
        .catch(error => {
          setIsLoading(false)
          console.log("ERROR: ", error)
          flashMessageContext.showMessage(`Error: Failed to save user.`)
        })

      clearUser()
    }
  }

  const disabledRoleChange = () => {
    return auth0User.email === formData.email
  }

  function renderTableHeader() {
    return (
      <thead className='tableHeaderRow'>
      <tr style={{cursor: 'pointer'}}>
        <th className="text-start">Actions</th>
        {renderSortableTableHeaderField('name', 'Name')}
        {renderSortableTableHeaderField('email', 'Email')}
        {renderSortableTableHeaderField('is_portal_admin', 'Role')}
        {renderSortableTableHeaderField('login_count', 'Login Count')}
      </tr>
      </thead>
    )
  }

  function handleSortColumns(column) {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  }

  function renderSortableTableHeaderField(fieldName, displayName = null) {
    if (!displayName) {
      displayName = fieldName
    }

    let arrowDirection;

    if (sortColumn === fieldName) {
      arrowDirection = sortDirection;
    } else {
      arrowDirection = 'asc'
    }

    return (
      <th className="text-start" onClick={() => handleSortColumns(fieldName)} key={fieldName}>
        {displayName}
        <span className={`pl-2 pr-1  ${fieldName === sortColumn ? 'text-warning' : 'text-muted'}`}>
          <span className={`ms-2 oi oi-chevron-${arrowDirection === 'asc' ? 'top' : 'bottom'}`} />
        </span>
      </th>
    );
  }

  const sortRows = (studentA, studentB) => {
    let sorted;
    switch (typeof studentA[sortColumn]) {
      case "string":
        sorted = studentA[sortColumn].localeCompare(studentB[sortColumn]);
        break;
      case "number":
        sorted = studentA[sortColumn] - studentB[sortColumn]
        break;
      case "boolean":
        sorted = (studentB[sortColumn] ? 1 : 0) - (studentA[sortColumn] ? 1 : 0)
        break;
      default:
        sorted = 0;
    }
    if (sortDirection === 'desc') sorted = -sorted
    return sorted
  }

  const renderRoleTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Proctors are able to observe assessment progress, and access individual student results (ISRs).
        School Test Coordinators have the same permissions as Proctors, and can also manage other users,
        students, and assessments.
    </Tooltip>
  );

  return (
    <section>
      <p>Logged in user: <span style={{ fontWeight: 'bold' }}>{auth0User.email}</span></p>
      <Form className={'bg-secondary-subtle px-4 pt-4 mb-3'}>
        <p>Add or edit an ASLA administrative user</p>
        <Row className="col-4 m-0 mb-3 border border-secondary-subtle">
          <Form.Group as={Col} className="mb-4" controlId="formRole">
            <Form.Label sm={2}>
              <Required>Role</Required>
            </Form.Label>
            <OverlayTrigger overlay={renderRoleTooltip} placement="right" delay={{show: 250, hide: 400}}>
              <Col sm={10}>
                <Form.Check
                  type="radio"
                  id="role-radio-proctor"
                  label='Proctor'
                  name='role-radio'
                  checked={!formData.isAdmin}
                  disabled={disabledRoleChange()}
                  onChange={() => updateRoleField(false)}
                />
                <Form.Check
                  type="radio"
                  id="role-radio-admin"
                  label='School Test Coordinator (STC)'
                  name='role-radio'
                  checked={!!formData.isAdmin}
                  disabled={disabledRoleChange()}
                  onChange={() => updateRoleField(true)}
                />
              </Col>
            </OverlayTrigger>
          </Form.Group>
        </Row>
        <Row className="mb-3 col-10">
          <Form.Group as={Col} className="mb-3" controlId="formName">
            <Form.Label sm={2}>
              <Required>Full name</Required>
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                value={formData.name || ""}
                onChange={(e) => updateFormField('name', e.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Col} className="mb-3" controlId="formEmail">
            <Form.Label sm={2}>
              <Required>Email</Required>
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                value={formData.email || ""}
                onChange={(e) => updateFormField('email', e.target.value)}
              />
            </Col>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} className="mb-3" controlId="formSaveButton">
            <Col style={{ textAlign: 'right' }}>
              <Button className="me-2" variant='secondary' name='cancelButton' onClick={clearUser}>
                Cancel
              </Button>
              <Button variant='primary' name='saveButton' onClick={saveUser}>
                Save
              </Button>
            </Col>
          </Form.Group>
        </Row>
      </Form>
      <Table striped hover>
        {renderTableHeader()}
        <tbody>
          {users.sort((a, b) => sortRows(a, b)).map(user => {
            return (
              <tr id={user.user_id} key={user.user_id}>
                <td>
                  {/* eslint-disable-next-line */}
                  <a href='#' onClick={(e) => remove(e, user)}>
                    <span className='oi oi-delete' title='remove' aria-hidden='true'></span>
                  </a>&nbsp;|&nbsp;
                  {/* eslint-disable-next-line */}
                  <a href='#' onClick={() => edit(user)}>
                    <span className='oi oi-pencil' title='edit' aria-hidden='true'></span>
                  </a>
                </td>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>
                  {user.is_portal_admin ? 'STC' : 'Proctor'}
                </td>
                <td>{user.login_count}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </section>
  )
}

UserManagement.propTypes = {
  users: PropTypes.array,
  addUser: PropTypes.func,
  removeUser: PropTypes.func,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  loadUsers: PropTypes.func,
}

export default UserManagement
