import React, {useContext, useEffect, useMemo, useReducer, useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import FormCheck from 'react-bootstrap/FormCheck';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';

import {useAuth0} from '../react-auth0-wrapper';
import {getSchoolIds} from './FetchData';

import {FlashMessageContext} from '../context/flash-message';

import '../styles/dashboard.css';

export const Dashboard = () => {

  const API_DEBUG = process.env.REACT_APP_API_DEBUG === 'true'

  /***********************
   * INITIALIZATION
   ***********************/

  const {getTokenSilently} = useAuth0();

  const flashMessageContext = useContext(FlashMessageContext);

  const [isLoaded, setIsLoaded] = useState(false);
  const [reloadAssessments, setReloadAssessments] = useState(false);
  const [assessments, setAssessments] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [selectedAssessments, setSelectedAssessments] = useState([]);
  const [allRowsSelected, setAllRowsSelected] = useState(false);
  const [assessmentStatusCounts, setAssessmentStatusCounts] = useState({notStarted: 0, running: 0, done: 0});

  // Reducer
  function filterReducer(state, action) {
    switch (action.type) {
      case 'SET_TESTING_GROUP_FILTER':
        setAllRowsSelected(false);
        setSelectedAssessments([]);
        return {...state, testingGroup: action.value === 'All' ? null : action.value};
      case 'SET_TEACHER_FILTER':
        setAllRowsSelected(false);
        setSelectedAssessments([]);
        return {...state, teacher: action.value === 'All' ? null : action.value};
      case 'SET_STATUS_FILTER':
        setAllRowsSelected(false);
        setSelectedAssessments([]);
        return {...state, status: action.value === 'All' ? null : action.value};
      case 'SET_IS_LOCKED_FILTER':
        setAllRowsSelected(false);
        setSelectedAssessments([]);
        return {...state, isLocked: action.value === 'All' ? null : action.value};
      case 'RESET_FILTER':
        setAllRowsSelected(false);
        setSelectedAssessments([]);
        return {};
      default:
        console.log('No action provided');
        return state;
    }
  }

  const [filter, filterDispatch] = useReducer(filterReducer, {}, () => {
  });

  const sortedAssessments = useMemo(() => {

    return assessments.sort((a, b) => {

      let sort = sortDirection === 'asc' ? 1 : -1;
      let valueA;
      let valueB;
      let comparator;

      // 1. Get the field and do any pre-modifications if needed
      if (sortColumn === 'remainingTasks') {
        valueA = a['totalTasks'] - a['completedTasks'];
        valueB = b['totalTasks'] - b['completedTasks'];
      } else {
        valueA = a[sortColumn];
        valueB = b[sortColumn];
      }

      // 2. Figure out the comparator type
      // Sort by alphabetical
      if (sortColumn === 'status'
        || sortColumn === 'testingGroup'
        || sortColumn === 'teacher'
        || sortColumn === 'lastName'
        || sortColumn === 'firstName'
        || sortColumn === 'username'
      ) {
        comparator = 'string';
      }

      // Sort by boolean
      else if (sortColumn === 'isLocked') {
        comparator = 'boolean';
      }

      // Sort numeric
      else if (sortColumn === 'age'
        || sortColumn === 'studentId'
        || sortColumn === 'completedTasks'
        || sortColumn === 'remainingTasks'
      ) {
        comparator = 'number';
      }

      // Sort date
      else if (sortColumn === 'lastUpdated') {
        comparator = 'date';
      }

      // 3. Do the sorting
      if (comparator === 'string') {
        valueA = valueA || '';
        return valueA.localeCompare(valueB) * sort;
      } else if (comparator === 'number' || comparator === 'boolean') {
        return (valueA - valueB) * sort;
      } else if (comparator === 'date') {
        valueA = valueA ? new Date(valueA) : 0;
        valueB = valueB ? new Date(valueB) : 0;
        return (valueA - valueB) * sort * -1;
      } else {
        return assessments;
      }

    });

  }, [assessments, sortColumn, sortDirection]);


  /***********************
   * LIFECYCLE METHODS
   ***********************/

  useEffect(() => {
    setIsLoaded(false);

    setReloadAssessments(true);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {

    // Get the Assessments
    getAssessmentsData()
      .then((assessments) => {
        setReloadAssessments(false);
        if (assessments) {
          const numNotStarted = assessments.filter(assessment => assessment.status === 'not started').length;
          const numRunning = assessments.filter(assessment => assessment.status === 'running').length;
          const numDone = assessments.filter(assessment => assessment.status === 'done').length;
          setAssessmentStatusCounts({notStarted: numNotStarted, running: numRunning, done: numDone});
          setIsLoaded(true);
          setAssessments(assessments);
        }
      })
      .finally(() => {
        setIsLoaded(true);
      });

    // eslint-disable-next-line
  }, [reloadAssessments]);


  /***********************
   * HTTP METHODS
   ***********************/

  /**
   * Get the assessments for the authenticated school
   *
   * @returns {Promise<AssessmentData>}
   */
  async function getAssessmentsData() {
    const apiPath = `${process.env.REACT_APP_API_PATH}/assessments`;
    const token = await getTokenSilently();
    const schoolIds = await getSchoolIds(token);
    const headers = new Headers({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
      'X-School-Ids': schoolIds,
    });

    if (API_DEBUG) {
      console.debug('getAssessmentsData headers', apiPath, headers);
    }
    return fetch(apiPath, {
      headers: headers,
    })
      .then(response => {
        if (response.status === 200) {
          const json = response.json();
          if (API_DEBUG) {
            console.debug('getAssessmentsData response', json);
          }
          return json;
        } else if (response.status === 403 || schoolIds === '') {
          flashMessageContext.showMessage(`You do not have a School ID assigned to you. Please contact ${process.env.REACT_APP_SUPPORT_EMAIL} for assistance.`);
        } else if (response.status === 404) {
          flashMessageContext.showMessage('The testing window is complete. Scores are available at the Reports Center.','success')
        } else {
          flashMessageContext.showMessage(`Status Code: ${response.status} Could not fetch assessments`);
        }
      })
      .catch(error => {
        console.error(error);
        flashMessageContext.showMessage(error.message);
      });
  }

  // /**
  //  * Update the assessments' lock status
  //  */
  async function updateAssessmentsStatus(action, assessmentIds) {
    const apiPath = `${process.env.REACT_APP_API_PATH}/assessments/lock`;
    const token = await getTokenSilently();
    const schoolIds = await getSchoolIds(token);

    return fetch(apiPath, {
      method: 'PATCH',
      body: JSON.stringify({action: action, assessmentIds: assessmentIds}),
      headers: new Headers(
        {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
          'X-School-Ids': schoolIds,
        },
      ),
    })
      .then(response => {
        if (response.status !== 204) {
          flashMessageContext.showMessage(`Status code: ${response.status}. Could not update the status of assessment.`);
        }
      })
      .catch(error => {
        console.error(error);
        flashMessageContext.showMessage(error.message);
      });
  }


  /***********************
   * EVENT HANDLERS
   ***********************/

  function handleSelectRowClicked(event) {
    const assessmentId = event.target.value;
    setAllRowsSelected(false);
    if (event.target.checked) {
      setSelectedAssessments([...selectedAssessments, parseInt(assessmentId)]);
    } else {
      const filteredRows = selectedAssessments.filter((rowId) => {
        return rowId !== parseInt(assessmentId);
      });
      setSelectedAssessments(filteredRows);
    }
  }

  function handleSelectAllRows(event) {
    if (event.target.checked) {
      setAllRowsSelected(true);
      setSelectedAssessments(assessments.map((assessment => {
        return assessment.id;
      })));
    } else {
      setAllRowsSelected(false);
      setSelectedAssessments([]);
    }
  }

  async function handleLockAssessments() {
    await updateAssessmentsStatus('lock', selectedAssessments);
    setSelectedAssessments(assessments.map((assessment => {
      return assessment.locked ? assessment.id : null;
    })));
    setReloadAssessments(true);
    setAllRowsSelected(false);
  }

  async function handleUnlockAssessments() {
    await updateAssessmentsStatus('unlock', selectedAssessments);
    setSelectedAssessments([]);
    setReloadAssessments(true);
    setAllRowsSelected(false);
  }

  function handleSortColumns(column) {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  }


  function handleResetFilter() {
    filterDispatch({
      type: 'RESET_FILTER',
    });

    let selects = [...document.getElementsByTagName('select')];
    return selects.map(options => {
      return options[0].selected = true;
    });
  }


  /***********************
   * STYLES
   ***********************/

  const headerHoverStyle = {cursor: 'pointer'};


  /***********************
   * RENDER METHODS
   ***********************/

  function renderFilter() {

    // Dropdowns for Testing Group, and Statuses
    const testingGroups = [...new Set(assessments.map(assessment => assessment.testingGroup))];
    const testingGroupOptions = () => {
      let options = [<option key={'allGroups'}>All</option>];
      testingGroups.forEach(option => {
        options.push(<option key={option}>{option}</option>);
      });
      return options;
    };

    const teachers = [...new Set(assessments.map(assessment => assessment.teacher))];
    const teacherOptions = () => {
      let options = [<option key={'allTeachers'}>All</option>];
      teachers.sort().forEach(option => {
        options.push(<option key={option}>{option}</option>);
      });
      return options;
    };

    const statuses = [...new Set(assessments.map(assessment => assessment.status))];
    const statusOptions = () => {
      let options = [<option key={'allStatuses'}>All</option>];
      statuses.forEach(option => {
        options.push(<option key={option}>{option}</option>);
      });
      return options;
    };

    return (
      <Row className={'alert alert-secondary'}>
        <Col>
          <Form className={'filter'}>
            <Row>
              <Col md={3}>
                <Form.Group controlId='filterForm.testingGroupSelect'>
                  <Form.Label>Testing Group</Form.Label>
                  <Form.Select onChange={(event) => filterDispatch({
                    type: 'SET_TESTING_GROUP_FILTER',
                    value: event.target.value,
                  })}>
                    {testingGroupOptions()}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId='filterForm.teacherSelect'>
                  <Form.Label>Teacher</Form.Label>
                  <Form.Select onChange={(event) => filterDispatch({
                    type: 'SET_TEACHER_FILTER',
                    value: event.target.value,
                  })}>
                    {teacherOptions()}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId='filterForm.statusSelect'>
                  <Form.Label>Status</Form.Label>
                  <Form.Select onChange={(event) => filterDispatch({
                    type: 'SET_STATUS_FILTER',
                    value: event.target.value,
                  })}>
                    {statusOptions()}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId='filterForm.isLockedSelect'>
                  <Form.Label>Is Locked?</Form.Label>
                  <Form.Select
                    onChange={(event) => filterDispatch({type: 'SET_IS_LOCKED_FILTER', value: event.target.value})}>
                    <option>All</option>
                    <option>yes</option>
                    <option>no</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className='mt-3' md={{span: 3}}>
                <Button onClick={() => handleResetFilter()}>
                  Clear Filter
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    );
  }

  function renderAssessmentStatusCounts() {
    return (
      <Row className='justify-content-md-center text-muted my-4 text-center'>
        <Col md={6}>
          <h5 className='assessmentStatusTitle'>Count of Assessments: </h5>
          <span className={'font-weight-bold'}>not started: </span> {assessmentStatusCounts.notStarted}
          <span className={'font-weight-bold'}> | running: </span> {assessmentStatusCounts.running}
          <span className={'font-weight-bold'}> | done: </span> {assessmentStatusCounts.done}
        </Col>
      </Row>
    );
  }

  function renderTableHeader() {
    return (
      <thead className='tableHeaderRow'>
      <tr style={headerHoverStyle}>
        <td><FormCheck onChange={handleSelectAllRows} checked={allRowsSelected} /></td>
        {renderSortableTableHeaderField('isLocked', 'Is Locked?')}
        {renderSortableTableHeaderField('testingGroup', 'Testing Group')}
        {renderSortableTableHeaderField('teacher', 'Teacher')}
        {renderSortableTableHeaderField('lastName', 'Last Name')}
        {renderSortableTableHeaderField('firstName', 'First Name')}
        {renderSortableTableHeaderField('username', 'Username')}
        {renderSortableTableHeaderField('studentId', 'Student ID')}
        {renderSortableTableHeaderField('age', 'Age')}
        {renderSortableTableHeaderField('completedTasks', 'Tasks Done')}
        {renderSortableTableHeaderField('remainingTasks', 'Tasks To Do')}
        {renderSortableTableHeaderField('currentTask', 'Current Task')}
        {renderSortableTableHeaderField('status', 'Status')}
        {renderSortableTableHeaderField('lastUpdated', 'Last Updated')}
      </tr>
      </thead>
    );
  }

  function renderSortableTableHeaderField(fieldName, displayName = null) {

    if (!displayName) {
      displayName = fieldName;
    }

    let arrowDirection;

    if (sortColumn === fieldName) {
      arrowDirection = sortDirection;
    } else {
      arrowDirection = 'asc';
    }

    return (
      <th onClick={() => handleSortColumns(fieldName)}>
        {displayName}
        <span className={`pl-2 pr-1  ${fieldName === sortColumn ? 'text-warning' : 'text-muted'}`}>
          <span className={`ms-2 oi oi-chevron-${arrowDirection === 'asc' ? 'top' : 'bottom'}`} />
        </span>
      </th>
    );
  }

  function renderAssessmentRows() {
    let rows = [];

    sortedAssessments.forEach((assessment) => {

        // Format fields
        const isLockedText = assessment.isLocked ? 'yes' : 'no';

        function renderLastUpdatedDate() {
          if (assessment.lastUpdated) {
            const lastUpdatedDate = new Date(assessment.lastUpdated);
            return (
              <span>
              {lastUpdatedDate.toLocaleDateString()}
                <br />
                {lastUpdatedDate.toLocaleTimeString()}
            </span>
            );
          }
        }

        if (filter) {
          // Filter rows
          if (filter.testingGroup && assessment.testingGroup && assessment.testingGroup !== filter.testingGroup) {
            return;
          }
          if (filter.teacher && assessment.teacher !== filter.teacher) {
            return;
          }
          if (filter.status && assessment.status !== filter.status) {
            return;
          }
          if (filter.isLocked && isLockedText !== filter.isLocked) {
            return;
          }
        }

        rows.push(
          <tr className={'dataRow'} key={assessment.id}>
            <td>
              <FormCheck
                value={assessment.id}
                onChange={handleSelectRowClicked}
                checked={selectedAssessments.includes(assessment.id)}
              />
            </td>
            <td className='tableRowDataAlignment'>{assessment.isLocked ?
              (<span
                className={'oi oi-lock-locked text-danger'}
                title={'Locked'}
                aria-hidden={'true'}
              />) :
              <span
                className={'oi oi-lock-unlocked text-success'}
                title={'Unlocked'}
                aria-hidden={'true'}
              />
            } </td>
            <td className='tableRowDataAlignment'>{assessment.testingGroup}</td>
            <td>{assessment.teacher}</td>
            <td>{assessment.lastName}</td>
            <td>{assessment.firstName}</td>
            <td>{assessment.username}</td>
            <td className='tableRowDataAlignment'>{assessment.studentId}</td>
            <td className='tableRowDataAlignment'>{assessment.age}</td>
            <td className='tableRowDataAlignment'>{assessment.completedTasks} of {assessment.totalTasks}</td>
            <td className='tableRowDataAlignment'>{assessment.totalTasks - assessment.completedTasks}</td>
            <td className='tableRowDataAlignment'>{assessment.currentTask === 1 ? 'Yes' : 'No'}</td>
            <td>{assessment.status}</td>
            <td className='tableRowDataAlignment'>{renderLastUpdatedDate()}</td>
          </tr>,
        );
      },
    );

    return rows;
  }

  function renderTable() {
    return (
      <div className='studentTableDiv' style={{maxHeight: '26rem', overflowY: 'auto'}}>
        <Table bordered hover >
          {renderTableHeader()}
          <tbody>
          {renderAssessmentRows()}
          </tbody>
        </Table>
      </div>
    );
  }

  function renderLockButtons() {
    return (
      <Row className='no-gutters row align-items-center justify-content-center mt-3'>
        <Col className='col text-end p-0'>
          <Button disabled={selectedAssessments.length === 0} onClick={handleLockAssessments}>
            Lock Assessments
          </Button>
        </Col>
        <Col className='col p-1'>
          <Button disabled={selectedAssessments.length === 0} onClick={handleUnlockAssessments}>
            Unlock Assessments
          </Button>
        </Col>
      </Row>
    );
  }

  // Main render method
  if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className={'mb-5'}>
        <Row className='justify-content-center my-3'>
          <Col md={11}>
            {renderAssessmentStatusCounts()}
            {renderFilter()}
            {renderTable()}
            {renderLockButtons()}
          </Col>
        </Row>
      </div>
    );
  }
};

/***********************
 * JSDOC DEFINITIONS
 ***********************/

/**
 * @typedef AssessmentData
 * @property {string} testingGroup
 * @property {string} teacher
 * @property {string} lastName
 * @property {string} firstName
 * @property {number} age
 * @property {number} studentId
 * @property {number} completedTasks
 * @property {string} currentTask
 * @property {number} totalTasks
 * @property {Date} lastUpdated
 */

export default Dashboard;
