import React from 'react';
import '../styles/footer.css';
import packageJson from '../../package.json';

const Footer = () => {
    const version = packageJson.version;
    const year = new Date().getFullYear();
    return (
        <footer>
            ASL Assessment v{version}, &copy; 2021-{year} ASL Education Center, LLC. All rights reserved.
        </footer>
    )
}

export default Footer
