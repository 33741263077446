import React, {useContext} from 'react';

import Alert from "react-bootstrap/Alert";
import {FlashMessageContext} from "../context/flash-message";

export const FlashMessage = () => {
  /*************************************************************
   * INITIALIZATION
   *************************************************************/

  const flashMessageContext = useContext(FlashMessageContext);

  // Default to a red alert style
  const messageClass = flashMessageContext.messageClass || 'danger';

  if (flashMessageContext.show) {
    return (
      <Alert variant={messageClass} onClose={() => flashMessageContext.show} dismissible>
        <div>{flashMessageContext.messageText}</div>
      </Alert>
    );
  } else {
    return null;
  }
};

export default FlashMessage;
