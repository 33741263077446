export const API_BASE = process.env.REACT_APP_API_PATH;
const API_DEBUG = process.env.REACT_APP_API_DEBUG === 'true'

const api = {
  get: async (route, headers = {}, base = API_BASE) => {
    const endpoint = `${base}${route}`;
    const mergedHeaders = Object.assign(
      headers,
      { Accept: 'application/json' },
    );
    const config = {
      headers: mergedHeaders
    };
    if (API_DEBUG) {
      console.debug("API_DEBUG (get request): ", endpoint, config);
    }
    return fetch(endpoint, config)
      .then((response) => {
        const json = response.json()
        if (API_DEBUG) {
          console.debug("API_DEBUG (get): ", json);
        }
        return json;
      })
      .catch(error => {
        console.error("Failed parsing response as JSON: ", error);
        return null;
      });
  },
  post: async (route, data = {}, headers = {}) => {
    const endpoint = `${API_BASE}${route}`;
    const mergedHeaders = Object.assign(
      headers,
      { 'Content-Type': 'application/json' },
    );
    const config = {
      method: 'POST',
      headers: mergedHeaders,
      body: JSON.stringify(data),
    };
    if (API_DEBUG) {
      console.debug("API_DEBUG (post request): ", endpoint, config);
    }
    return fetch(endpoint, config)
      .then((response) => {
        const json = response.json()
        if (API_DEBUG) {
          console.debug("API_DEBUG (post response): ", json);
        }
        return json;
      })
      .catch(error => {
        console.log("Failed parsing response as JSON: ", error);
        return null;
      });
  },
  delete: async (route, data = {}, headers = {}) => {
    const endpoint = `${API_BASE}${route}`;
    const mergedHeaders = Object.assign(
      headers,
      { 'Content-Type': 'application/json' },
    );
    const config = {
      method: 'DELETE',
      headers: mergedHeaders,
      body: JSON.stringify(data),
    };
    return fetch(endpoint, config)
      .then((response) => response)
      .catch(error => {
        console.log("Failed parsing response as JSON: ", error);
        return null;
      });
  }
};

export default api
