const HealthCheck = () => {
  return JSON.stringify({
    product: 'asla',
    service: 'portal',
    environment: process.env.REACT_APP_ENVIRONMENT,
    status: 'ok',
    datetime: (new Date()).toISOString()
  }, null, 2)
}

export default HealthCheck
