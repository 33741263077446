import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';

const AssessmentManagement = ({
  assessmentPaused,
  assessmentStatusLoading,
  autoAssignExams,
  closeAndScore,
  pauseOrUnpauseAssessment
}) => {
  const pauseUnpause = assessmentPaused ? 'Resume' : 'Pause';
  const pauseVariant = assessmentPaused ? 'success' : 'danger';
  return (
  <>
      <h1>Assessment Management</h1>
      <p>Auto-assign exams to all students (will do so based on age of student):</p>
      <Button onClick={autoAssignExams} variant='warning '>Assign Exams</Button>

      <hr />

      <h3>
        Close and Score
      </h3>
      <p>Close testing window and score assessment:</p>
      <Button onClick={closeAndScore} variant='info'>Generate Scores</Button>

      <hr />

      <h3>
        {!assessmentStatusLoading && (pauseUnpause + ' ')}Testing
      </h3>
      <p>
        {!assessmentStatusLoading && (assessmentPaused
            ? 'Unpause assessment and allow students to log in.'
            : 'Pause assessment and prevent students from logging in.'
        )}
      </p>
      <Button onClick={pauseOrUnpauseAssessment} variant={pauseVariant}>
        {(assessmentStatusLoading && <Spinner />) || (pauseUnpause + ' Testing')}
      </Button>
    </>
  );
};

AssessmentManagement.propTypes = {
  assessmentPaused: PropTypes.bool,
  assessmentStatusLoading: PropTypes.bool,
  autoAssignExams: PropTypes.func,
  closeAndScore: PropTypes.func,
  pauseOrUnpauseAssessment: PropTypes.func
};

export default AssessmentManagement;
