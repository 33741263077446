import React, { useContext, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Button, Container, Col, Row, Form } from 'react-bootstrap';

import { FlashMessageContext } from '../context/flash-message';
import { useAuth0 } from '../react-auth0-wrapper';
import { getSchoolIds } from './FetchData';

import api from '../api';
import {Required} from './common';

// eslint-disable-next-line react/prop-types
const NDA = ({ document, setSignedNDA }) => {
  const markdown = document

  const currentYear = (new Date()).getFullYear()
  const previousSchoolYear = `${currentYear - 1}/${currentYear}`
  const nextSchoolYear = `${currentYear}/${currentYear + 1}`
  const formFields = [
    {
      name: 'name',
      label: 'Full name',
      type: 'text',
      default: ''
    },
    {
      name: 'role',
      label: 'Role',
      type: 'select',
      options: [
        {
          value: 'school_test_coordinator',
          label: 'School Test Coordinator'
        },
        {
          value: 'technology_coordinator',
          label: 'Technology Coordinator'
        },
        {
          value: 'test_administrator',
          label: 'Test Administrator'
        },
        {
          value: 'proctor',
          label: 'Proctor'
        },
        {
          value: 'paraprofessional',
          label: 'Paraprofessional'
        },
        {
          value: 'other',
          label: 'Other'
        }
      ],
      default: ''
    },
    {
      name: 'job_title',
      label: 'Job title',
      type: 'text',
      default: ''
    },
    {
      name: 'school_year',
      label: 'School year',
      type: 'select',
      default: '',
      options: [
        {
          value: previousSchoolYear,
          label: previousSchoolYear
        },
        {
          value: nextSchoolYear,
          label: nextSchoolYear
        },
      ]
    }
  ]
  const [otherSpecified, setOtherSpecified] = useState("")
  const defaultFormValues = () => Object.fromEntries(formFields.map(f => [f.name, f.default]))
  const [formValues, setFormValues] = useState(defaultFormValues())
  const { getTokenSilently, user } = useAuth0();
  const otherSelected = () => formValues.role === "other"
  const flashMessageContext = useContext(FlashMessageContext)

  const save = async () => {
    const allFieldsFilledOut = Object.keys(formValues).reduce((result, field) => {
      return result && !!formValues[field]
    }, true)
    const otherCompleted = !otherSelected() || (otherSelected() && !!otherSpecified)

    if (allFieldsFilledOut && otherCompleted) {
      const token = await getTokenSilently();
      const schoolIds = await getSchoolIds(token);
      const body = {
        ...formValues,
        role_specified: otherSelected() ? otherSpecified : formValues.role,
        email: user.email
      }
      const headers = { 'X-School-Ids': schoolIds }
      api.post('/admin/ndas', body, headers)
        .then(response => {
          if (response) {
            flashMessageContext.showMessage('NDA signed successfully.', 'success')
            setSignedNDA(true)
          } else {
            flashMessageContext.showMessage('Failed to sign NDA.')
          }
        })
        .catch(error => {
          console.error(error)
          flashMessageContext.showMessage('Failed to sign NDA.')
        })
    } else {
      window.location.href = "#"
      flashMessageContext.showMessage('Please fill out all fields.')
    }
  }

  return (
    <>
      <Container className={"m-3 mb-6"}>
        <h1>NDA and Security Agreement</h1>
        <p>
          Please read the following non-disclosure and security agreement document
          and fill out the required fields below to sign.
        </p>
      </Container>
      <Container className="container-md">
        <ReactMarkdown className={"bg-secondary-subtle p-5 border border-dark rounded-2"}>{markdown}</ReactMarkdown>
        <Form id={"nda-fields"} className={"col-sm-4 mt-4"}>
          {formFields.map((field) => {
            return (
              <Row className="mb-3" key={field.name}>
                <Form.Group as={Col} className="mb-3" controlId={`form${field.name}`}>
                  <Form.Label sm={2}>
                    <Required>{field.label}</Required>
                  </Form.Label>
                  {field.type === "select" ?
                    <Form.Select
                      aria-label={`Select ${field.name.toUpperCase()}`}
                      name={field.name}
                      value={formValues[field.name] || field.default}
                      onChange={(e) => setFormValues({ ...formValues, [e.target.name]: e.target.value })}
                    >
                      <option value="">--</option>
                      {field.options.map(option => (
                        <option value={option.value} key={option.value}>{option.label}</option>
                      ))}
                    </Form.Select>
                    :
                    <Form.Control
                      type={field.type}
                      name={field.name}
                      value={formValues[field.name] || field.default}
                      onChange={(e) => setFormValues({ ...formValues, [field.name]: e.target.value })}
                    />}
                </Form.Group>
              </Row>
            )
          })}
          {otherSelected() && <Form.Control
            type="text"
            name="other_specified"
            value={otherSpecified}
            onChange={(e) => setOtherSpecified(e.target.value)}
          />}
          <Row>
            <Form.Group as={Col} className="mb-3" controlId="formSaveButton">
              <Col style={{ textAlign: 'right' }}>
                <Button
                  variant="success"
                  name="saveButton"
                  size="lg"
                  onClick={save}
                  className="clickable"
                >Sign</Button>
              </Col>
            </Form.Group>
          </Row>
        </Form>
      </Container>
    </>
  )
}

export default NDA
