import React, {useEffect, useRef, useState} from 'react';
import {BrowserRouter, Link, Route, Switch} from 'react-router-dom';

// Contexts
import {FlashMessageContextProvider} from './context/flash-message';
import {useAuth0} from './react-auth0-wrapper';

// React Bootstrap
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

// Components
import FlashMessage from './components/FlashMessage';
import Dashboard from './components/Dashboard';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import PrivateRoute from './components/PrivateRoute';
import AdminPage from './components/AdminPage';
import HealthCheck from './components/HealthCheck';
import HelpForm from './components/HelpForm';
import Reports from './components/Reports';
import NDA from './components/NDA';
import Login from './components/Login';

import api from './api';
import './App.css';

// eslint-disable-next-line no-unused-vars
function App(props) {
  const NDA_SIGNED = 'signed';
  const {loading, isAuthenticated, user} = useAuth0();
  const currentPath = window.location.pathname;
  const isHealthCheck = currentPath === '/healthcheck';
  const [ndaStatus, setNdaStatus] = useState('incomplete');
  const [ndaUri, setNdaUri] = useState('');
  const [ndaMarkdown, setNdaMarkdown] = useState('');
  const ndaIsUnsigned = ndaStatus !== NDA_SIGNED;


  const ndaUriLoaded = useRef(false);
  const ndaContentLoaded = useRef(false);
  const userLoaded = useRef(false);

  useEffect(() => {
    let needNdaContent = false;
    if (user && !userLoaded.current) {
      api.get(`/admin/nda/signature?email=${encodeURIComponent(user.email)}`)
        .then(signature => {
          if (signature && signature.user_email === user.email) {
            setNdaStatus(NDA_SIGNED);
          } else {
            needNdaContent = true;
          }
        })
        .catch(error => console.debug(error));
      return () => {
        userLoaded.current = true;
      };
    }
    if (needNdaContent || ndaIsUnsigned) {
      // Fetch the NDA document URL from the database (points to S3)
      if (!ndaUri) {
        api.get('/admin/nda')
          .then(result => {
            result && result.url && setNdaUri(result.url);
          })
          .catch(error => console.error(error));
        return () => {
          ndaUriLoaded.current = true;
        };
      }
      if (!ndaMarkdown) {
        fetch(ndaUri)
          .then((response) => response.text())
          .then(data => {
            setNdaMarkdown(data);
          })
          .catch(error => console.error(error));

        return () => {
          ndaContentLoaded.current = true;
        };
      }
    }
  }, [user, ndaUri, ndaMarkdown, ndaIsUnsigned]);

  if (loading) {
    return (
      <div>Loading...</div>
    );
  }

  if (!isHealthCheck && isAuthenticated && ndaIsUnsigned) {
    return (
      <FlashMessageContextProvider>
        <NavBar />
        <FlashMessage />
        <NDA document={ndaMarkdown} setSignedNDA={() => setNdaStatus(NDA_SIGNED)} />
      </FlashMessageContextProvider>
    );
  }

  return (
    <FlashMessageContextProvider>
      <BrowserRouter>
        {!isHealthCheck && <NavBar />}
        {!isHealthCheck && <FlashMessage />}
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/support' component={HelpForm} />
          <Route path='/healthcheck' exact component={HealthCheck} />
          <PrivateRoute path='/dashboard' component={Dashboard} />
          <PrivateRoute path='/reports' component={Reports} />
          <PrivateRoute path='/admin' component={AdminPage} />
          <PrivateRoute path='/login' component={Login} />
        </Switch>
        <Footer />
      </BrowserRouter>
    </FlashMessageContextProvider>
  );
}


const Home = () => {
  const {isAuthenticated, isAdmin} = useAuth0();

  function welcomeMessage() {
    if (!isAuthenticated) {
      return (
        <Container className='text-start'>
          <Row className={'alert alert-secondary'}>You are not logged in.</Row>
          <Row>
            <ul>
              <li>To begin, click the <Link to={'/login'}>Login</Link> button.</li>
            </ul>
          </Row>
        </Container>
      );
    } else {
      if (isAdmin) {
        return (
          <Container className='text-start'>
            <Row className={'alert alert-secondary'}>You are logged in as an administrator.</Row>
            <Row>
              <ul>
                <li>Click the <Link to={'/admin'}>Administration</Link> button to manage system users, students, and
                  assessments
                </li>
                <li>Click the <Link to={'/reports'}>Reports</Link> button to view assessment reports</li>
                <li>Click the <Link to={'/dashboard'}>Live Assessments</Link> button to perform proctoring functions
                  (when a test is running)
                </li>
              </ul>
            </Row>
          </Container>
        );
      } else {
        return (
          <Container className='text-start'>
            <Row className={'alert alert-secondary'}>You are logged in as a proctor.</Row>
            <Row>
              <ul>
                <li>Click the <Link to={'/dashboard'}>Live Assessments</Link> button to perform proctoring functions
                  (when a test is running)
                </li>
              </ul>
            </Row>
          </Container>
        );
      }
    }
  }

  return (
    <Container className='welcome-container'>
      <h1>Welcome to the ASLA Portal</h1>
      <div className='welcome-caption'>
        {welcomeMessage()}
      </div>
    </Container>
  );
};

export default App;
