import React, { useState } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import '../styles/helpForm.css'

export const HelpForm = () =>  {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [category, setCategory] = useState('');
  
    async function submitForm(e) {
      e.preventDefault();
  
      const webhookUrl = 'https://hooks.slack.com/services/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';
  
      const data = {
        "text": `Name: ${name} \n Email: ${email} \n Category: ${category} \n Message: ${message}`,
      }
      if (name && email && message) {
          let res = await axios.post(webhookUrl, JSON.stringify(data), {
            withCredentials: false,
            transformRequest: [(data, headers) => {
              delete headers.post["Content-Type"]
              return data
            }]
          })
          if (res.status === 200) {
            alert("Message Sent!")
      
            //clear state so text boxes clear
            setName('');
            setEmail('');
            setCategory('');
            setMessage('');
          } else {
            alert("There was an error.  Please try again later.")
          }
        }
        else {
            alert("Please fill out all required fields")
      }
  
  
    }
  
    return (
      <div className="flex ml-5">
        <div className="w-1/2 container mx-auto ml-5">
            <form className="shadow-md rounded px-8 pt-6 pb-8 mb-4 self-center ml-5">
            <h3 className="ml-2 mb-4">Submit your inquiry or request</h3>
                <div className="mt-3"> 
                    <label className="block text-gray-700 text-sm font-bold mb-2 mr-3" htmlFor="name">
                        Name *
                    </label>
                    <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        id="name"
                        type="text"
                        placeholder="FirstName LastName"
                        value={name}
                        onChange={(e) => { setName(e.target.value) }}
                        required
                    />
                </div>

                <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2 mr-3" htmlFor="email">
                        Email *
                    </label>
                    <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        id="email"
                        type="email"
                        placeholder="email@example.com"
                        value={email}
                        onChange={(e) => { setEmail(e.target.value) }}
                        required
                    />
                </div>
                <div>
                    <label className="block text-gray-700 text-sm font-bold mb-6 mr-3" htmlFor="category">
                        Select a Topic
                    </label>
                    <select 
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white mb-3"
                        onChange={(e) => { setCategory(e.target.value) }} 
                        required
                        
                        >
                        <option value="Proctor Portal">Proctor Portal</option>
                        <option value="Student Assessment">Student Assessment</option>
                        <option value="Other...">Other...</option>
                    </select>
                </div>

                <div>
                    <label className="block text-gray-700 text-sm font-bold mb-6 mr-3" htmlFor="message">
                        Description *
                    </label>
                    <textarea
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="message"
                        type="message"
                        placeholder="Please enter the details of your request here"
                        rows="6"
                        cols="40"
                        value={message}
                        onChange={(e) => { setMessage(e.target.value) }}
                        required
                    />
                </div>

                <Button
                    className="mt-4 shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-black font-bold py-2 px-4 rounded"
                    onClick={(e) => {
                        submitForm(e)
                    }}
                >
                    Submit
                </Button>
            </form>
        </div>
      </div>
    );
}


export default HelpForm;