import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';

import {Accordion, Button, Col, Form, Modal, Row, Table} from 'react-bootstrap';


import {useAuth0} from '../react-auth0-wrapper';
import {getSchoolIds} from './FetchData';
import {Required} from './common';
import {FlashMessageContext} from '../context/flash-message';
import api from '../api';
import Container from 'react-bootstrap/Container';

const StudentManagement = ({assessmentId, students, setStudents}) => {
  const defaultFormValues = {
    studentId: '',
    username: '',
    assignedExam: '',
    firstName: '',
    middleName: '',
    lastName: '',
    preferredName: '',
    dateOfBirth: '',
    parentage: '',
    gender: '',
    raceEthnicity: '',
    isAdult: false,
    studentRating: '1',
    testingGroup: '',
    teacher: '',
  };

  const [formData, setFormData] = useState(defaultFormValues);
  const {getTokenSilently} = useAuth0();

  const [sortDirection, setSortDirection] = useState('asc');
  const [sortColumn, setSortColumn] = useState('student_id');
  const [show, setShow] = useState(false);

  const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL || 'support@asledcenter.org';
  const flashMessageContext = useContext(FlashMessageContext);

  const updateForm = (name, value) => {
    setFormData((data) => ({...data, [name]: value}));
  };

  const edit = (student_id) => {
    const student = students.find(s => s.student_id === student_id);
    if (!student) {
      setFormData(defaultFormValues);
    } else {
      setFormData({
        studentId: student.student_id || '',
        username: student.username || '',
        assignedExam: student.exam || '',
        firstName: student.first_name || '',
        middleName: student.middle_name || '',
        lastName: student.last_name || '',
        preferredName: student.preferred_name || '',
        dateOfBirth: student.date_of_birth || '',
        parentage: student.parentage || '',
        gender: student.gender || '',
        raceEthnicity: student.race_ethnicity || '',
        isAdult: student.is_adult || false,
        studentRating: student.student_rating || '1',
        testingGroup: student.testing_group || '',
        teacher: student.teacher || '',
      });
    }
    handleShow();
  };

  const remove = (event, student) => {
    event.preventDefault();

    let fullName = student.first_name + ' ';
    if (student.middle_name) {
      fullName += student.middle_name + ' ';
    }
    fullName += student.last_name;

    const message = `Are you sure you want to remove ${fullName}?`;
    const result = window.confirm(message);
    if (result) {
      removeStudent(student);
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  function handleSortColumns(column) {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  }

  function renderSortableTableHeaderField(fieldName, displayName = null) {
    if (!displayName) {
      displayName = fieldName;
    }

    let arrowDirection;

    if (sortColumn === fieldName) {
      arrowDirection = sortDirection;
    } else {
      arrowDirection = 'asc';
    }

    return (
      <th onClick={() => handleSortColumns(fieldName)} key={fieldName}>
        {displayName}
        <span className={`pl-2 pr-1  ${fieldName === sortColumn ? 'text-warning' : 'text-muted'}`}>
          <span className={`ms-2 oi oi-chevron-${arrowDirection === 'asc' ? 'top' : 'bottom'}`} />
        </span>
      </th>
    );
  }

  const sortRows = (studentA, studentB) => {
    let sorted;
    switch (typeof studentA[sortColumn]) {
      case 'string':
        sorted = studentA[sortColumn].localeCompare(studentB[sortColumn]);
        break;
      case 'number':
        sorted = studentA[sortColumn] - studentB[sortColumn];
        break;
      case 'boolean':
        sorted = (studentB[sortColumn] ? 1 : 0) - (studentA[sortColumn] ? 1 : 0);
        break;
      default:
        sorted = 0;
    }
    if (sortDirection === 'desc') sorted = -sorted;
    return sorted;
  };

  function renderTableHeader() {
    return (
      <thead className='tableHeaderRow'>
      <tr style={{cursor: 'pointer'}}>
        <th>Actions</th>
        {renderSortableTableHeaderField('student_id', 'Student ID')}
        {renderSortableTableHeaderField('username', 'Username')}
        {renderSortableTableHeaderField('first_name', 'First Name')}
        {renderSortableTableHeaderField('last_name', 'Last Name')}
        {renderSortableTableHeaderField('date_of_birth', 'Age')}
        {renderSortableTableHeaderField('parentage', 'Parentage')}
        {renderSortableTableHeaderField('gender', 'Gender')}
        {renderSortableTableHeaderField('race_ethnicity', 'Race/Ethnicity')}
        {renderSortableTableHeaderField('student_rating', 'Rating')}
        {renderSortableTableHeaderField('teacher', 'Teacher')}
        {renderSortableTableHeaderField('testing_group', 'Testing Group')}
        {renderSortableTableHeaderField('exam', 'Assigned Exam')}
      </tr>
      </thead>
    );
  }

  function formatExamText(exam) {
    let age_string = '';
    if (exam) {
      let age_range = exam.split('_')[1];
      if (age_range.includes('plus')) {
        age_string = age_range.split('plus')[0] + ' and up';
      } else if (age_range.includes('to')) {
        age_string = 'ages ' + age_range.split('to')[0] + ' to ' + age_range.split('to')[1];
      } else {
        age_string = age_range;
      }
    }
    return age_string;
  }

  function renderStudentRows() {
    if (!students) {
      return null;
    }
    return students.sort((a, b) => sortRows(a, b)).map(student => {
      if (!student) {
        return null;
      }
      return (
        <tr key={student.id} id={student.id} className='student'>
          <td>
            {/* eslint-disable-next-line */}
            <a href='#' onClick={(e) => remove(e, student)}>
              <span className='oi oi-delete' title='remove' aria-hidden='true'></span>
            </a>&nbsp;|&nbsp;
            {/* eslint-disable-next-line */}
            <a href='#' onClick={() => edit(student.student_id)}>
              <span className='oi oi-pencil' title='edit' aria-hidden='true'></span>
            </a>
          </td>
          <td>{student.student_id}</td>
          <td>{student.username}</td>
          <td>{student.first_name}</td>
          <td>{student.last_name}</td>
          <td>{student.age}</td>
          <td>{student.parentage}</td>
          <td>{student.gender ? student.gender.toUpperCase() : ''}</td>
          <td>{student.race_ethnicity}</td>
          <td>{student.student_rating}</td>
          <td>{student.teacher}</td>
          <td>{student.testing_group}</td>
          <td>{formatExamText(student.exam)}</td>
        </tr>
      );
    });
  }

  function renderTable() {
    return (
      <div className='tableDiv'>
        <Table bordered hover style={{margin: 0}}>
          {renderTableHeader()}
          <tbody>
          {renderStudentRows()}
          </tbody>
        </Table>
        <Container className="ms-0 ps-0 pt-4">
          <Row>
            <Col xs={3}>
              <Button variant={'primary'} onClick={() => edit('')}>
                Add Student
              </Button>
            </Col>
            <Col xs={9}></Col>
          </Row>
        </Container>
      </div>
    );
  }

  function renderEditForm() {
    return (
      <Modal
        show={show}
        onHide={handleClose}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >

        <Modal.Header closeButton>
          <Modal.Title>Edit Student</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <p><span style={{color: 'red'}}>*</span>&nbsp;Required Fields</p>
            <Row className='mb-1'>
              <Form.Group as={Col} className='mb-1' controlId='formStudentId'>
                <Form.Label sm={1}>
                  ID
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    type='text'
                    disabled
                    value={formData.studentId} />
                </Col>
              </Form.Group>

              <Form.Group as={Col} className='mb-1' controlId='formUsername'>
                <Form.Label sm={1}>
                  Username
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    type='text'
                    disabled
                    value={formData.username}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Col} className='mb-1' controlId='formAssignedExam'>
                <Form.Label sm={1}>
                  Assigned Exam
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    type='text'
                    disabled
                    value={formData.assignedExam}
                  />
                </Col>
              </Form.Group>
            </Row>

            <Row className='mb-1'>
              <Form.Group as={Col} className='mb-1' controlId='formFirstName'>
                <Form.Label sm={1}>
                  <Required>First Name</Required>
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    type='text'
                    name='firstName'
                    value={formData.firstName || ''}
                    onChange={(e) => updateForm(e.target.name, e.target.value)}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Col} className='mb-1' controlId='formMiddleName'>
                <Form.Label sm={1}>
                  Middle
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    type='text'
                    name='middleName'
                    value={formData.middleName || ''}
                    onChange={(e) => updateForm(e.target.name, e.target.value)}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Col} className='mb-1' controlId='formLastName'>
                <Form.Label sm={1}>
                  <Required>Last Name</Required>
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    type='text'
                    name='lastName'
                    value={formData.lastName || ''}
                    onChange={(e) => updateForm(e.target.name, e.target.value)}
                  />
                </Col>
              </Form.Group>
            </Row>

            <Row className='mb-1'>
              <Form.Group as={Col} className='mb-1' controlId='formPreferredName'>
                <Form.Label sm={1}>
                  Preferred Name
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    type='text'
                    name='preferredName'
                    value={formData.preferredName || ''}
                    onChange={(e) => updateForm(e.target.name, e.target.value)}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Col} className='mb-1' controlId='formDateOfBirth'>
                <Form.Label sm={1}>
                  <Required>Date of Birth</Required>
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    type='date'
                    name='dateOfBirth'
                    value={formData.dateOfBirth || ''}
                    onChange={(e) => updateForm(e.target.name, e.target.value)}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Col} className='mb-1' controlId='formLastName'>
                <Form.Label sm={1}>
                  <Required>Parentage (Primary Parent)</Required>
                </Form.Label>
                <Col sm={12}>
                  <Form.Select
                    aria-label='Select Parentage'
                    name='parentage'
                    value={formData.parentage || ''}
                    onChange={(e) => updateForm(e.target.name, e.target.value)}
                  >
                    <option value=''>--</option>
                    <option value='HP'>Hearing Parents</option>
                    <option value='DP'>Deaf Parents</option>
                  </Form.Select>
                </Col>
              </Form.Group>
            </Row>

            <Row className='mb-1'>
              <Form.Group as={Col} className='mb-1' controlId='formGender'>
                <Form.Label sm={1}>
                  <Required>Gender</Required>
                </Form.Label>
                <Col sm={12}>
                  <Form.Select
                    aria-label='Gender select menu'
                    name='gender'
                    value={formData.gender || ''}
                    onChange={(e) => updateForm(e.target.name, e.target.value)}
                  >
                    <option value=''>--</option>
                    <option value='m'>Male</option>
                    <option value='f'>Female</option>
                    <option value='n'>Non-binary</option>
                  </Form.Select>
                </Col>
              </Form.Group>

              <Form.Group as={Col} className='mb-1' controlId='formRaceEthnicity'>
                <Form.Label sm={1}>
                  <Required>Race / Ethnicity</Required>
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    type='text'
                    name='raceEthnicity'
                    value={formData.raceEthnicity || ''}
                    onChange={(e) => updateForm(e.target.name, e.target.value)}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Col} className='mb-1' controlId='formIsAdult'>
                <Form.Label sm={1}>
                  <Required>Is Adult?</Required>
                </Form.Label>
                <Col sm={12}>
                  <Form.Select
                    aria-label='Is Adult select menu'
                    name='isAdult'
                    value={formData.isAdult ? 'true' : 'false'}
                    onChange={(e) => updateForm(e.target.name, e.target.value === 'true')}
                  >
                    <option value='false'>No</option>
                    <option value='true'>Yes</option>
                  </Form.Select>
                </Col>
              </Form.Group>
            </Row>

            <Row className='mb-1'>
              <Form.Group as={Col} className='mb-1' controlId='formTestingGroup'>
                <Form.Label sm={1}>
                  Testing Group
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    type='text'
                    name='testingGroup'
                    value={formData.testingGroup || ''}
                    onChange={(e) => updateForm(e.target.name, e.target.value)}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Col} className='mb-1' controlId='formTeacher'>
                <Form.Label sm={1}>
                  Teacher
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    type='text'
                    name='teacher'
                    value={formData.teacher || ''}
                    onChange={(e) => updateForm(e.target.name, e.target.value)}
                  />
                </Col>
              </Form.Group>
            </Row>

            <Row className='mb-1'>
              <Form.Group as={Col} className='mb-1' controlId='formStudentRating'>
                <Form.Label sm={1}>
                  <Required>Student Rating: Quality of Student Functioning / Performance</Required>
                </Form.Label>
                <Form.Select
                  aria-label='student rating select menu'
                  name='studentRating'
                  value={formData.studentRating || '1'}
                  onChange={(e) => updateForm(e.target.name, e.target.value)}
                >
                  <option value='1'>Level #1</option>
                  <option value='2'>Level #2</option>
                  <option value='3'>Level #3</option>
                  <option value='4'>Level #4</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} className='mb-1' controlId='formStudentRatingDesc'>
                <Form.Label sm={1}>Level Descriptions</Form.Label>
                <Accordion>
                  <Accordion.Item eventKey='0'>
                    <Accordion.Header>Levels</Accordion.Header>
                    <Accordion.Body>
                      <ol>
                        <li>Student is performing as expected for their age in school.</li>
                        <li>Student is performing below average but is not diagnosed with specific learning, behavioral,
                          or medical disorders.
                        </li>
                        <li>Student is performing below average but is diagnosed with having a learning disability as
                          follows:
                        </li>
                        <ul>
                          <li>ADD/ADHD</li>
                          <li>Cognitive Processing Disorder</li>
                          <li>Dyscalculia</li>
                          <li>Dysgraphia</li>
                          <li>Dyslexia</li>
                          <li>Dyspraxia</li>
                          <li>Executive Functioning</li>
                        </ul>
                        <li>Student is performing below average but is diagnosed with having at least one of the
                          following:
                        </li>
                        <ul>
                          <li>Autistic Spectrum Disorder (ASD)</li>
                          <li>Developmental Disability</li>
                          <li>Dual Sensory Impairment</li>
                          <li>Emotional Disturbance</li>
                          <li>Intellectual Impairment</li>
                          <li>Neurological Impairment</li>
                          <li>Pervasive Development Disability (PDD)</li>
                        </ul>
                      </ol>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' name='cancelButton' onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='primary' name='saveButton' onClick={saveStudent}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const saveStudent = async () => {
    const {
      firstName,
      lastName,
      dateOfBirth,
      parentage,
      gender,
      raceEthnicity,
      studentRating,
    } = formData;
    if (!firstName || !lastName || !dateOfBirth || !parentage || !gender || !raceEthnicity || !studentRating) {
      flashMessageContext.showMessage('Please fill in required fields before saving.');
    } else {
      const token = await getTokenSilently();
      const schoolIds = await getSchoolIds(token);
      api.post(
        `/assessments/${assessmentId}/students`,
        formData,
        {'x-school-ids': schoolIds},
      ).then((newOrUpdatedStudent) => {
        const index = students.findIndex(s => s.id === newOrUpdatedStudent.id);
        let rest = students;
        if (index >= 0) {
          rest = [
            ...students.slice(0, index),
            ...students.slice(index + 1),
          ];
        }
        setStudents([...rest, newOrUpdatedStudent]);
        handleClose();
        window.location.href = `#${newOrUpdatedStudent.id}`;
      }).catch((error) => {
        flashMessageContext.showMessage(`Save failed: ${error}`);
        console.error(error);
      });
    }
  };

  const removeStudent = async (student) => {
    const token = await getTokenSilently();
    const schoolIds = await getSchoolIds(token);
    api.delete(
      `/assessments/${assessmentId}/students`,
      {studentId: student.student_id},
      {'x-school-ids': schoolIds},
    ).then((response) => {
      if (response.status === 204) {
        const index = students.findIndex(s => s.id === student.id);
        if (index >= 0) {
          setStudents([
            ...students.slice(0, index),
            ...students.slice(index + 1),
          ]);
        }
        window.alert('Student removed.');
      } else if (response.status === 400) {
        flashMessageContext.showMessage('You cannot remove a student that has already started the test.');
      } else if (response.status === 403 || schoolIds === '') {
        flashMessageContext.showMessage(`You do not have a School ID assigned to you. Please contact ${supportEmail} for assistance.`);
      }
    });
  };

  return <>
    {renderEditForm()}
    {renderTable()}
  </>;
};

StudentManagement.propTypes = {
  assessmentId: PropTypes.number,
  students: PropTypes.arrayOf(PropTypes.shape({
    student_id: PropTypes.number.isRequired,
    username: PropTypes.string,
    first_name: PropTypes.string,
    middle_name: PropTypes.string,
    last_name: PropTypes.string,
    preferred_name: PropTypes.string,
    date_of_birth: PropTypes.string,
    parentage: PropTypes.string,
    gender: PropTypes.string,
    raceEthnicity: PropTypes.string,
    isAdult: PropTypes.bool,
    studentRating: PropTypes.number,
    testingGroup: PropTypes.string,
    teacher: PropTypes.string,
  })).isRequired,
  setStudents: PropTypes.func.isRequired,
};

/**
 * @typedef StudentManagement
 * @property {number} assessmentId
 * @property {Student[]} students
 * @property {function} setStudents
 */

/**
 * @typedef Student
 * @property {string} student_id
 * @property {string} username
 * @property {string} first_name
 * @property {string} middle_name
 * @property {string} last_name
 * @property {string} preferred_name
 * @property {string} date_of_birth
 * @property {string} parentage
 * @property {string} race_ethnicity
 * @property {string} student_rating
 * @property {boolean} is_adult
 * @property {string} studentRating
 * @property {string} testingGroup
 * @property {string} teacher
 * @property {string} exam
 */

export default StudentManagement;
