import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";

import { useAuth0 } from "../react-auth0-wrapper";
import '../styles/navbar.css'

const NavBar = () => {
  const { isAuthenticated, loginWithRedirect, logout, isAdmin } = useAuth0();
  let currentURL = window.location.pathname;
  const [isStatusDashboard, setIsStatusDashboard] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (currentURL === '/reports') {
      setIsStatusDashboard(false);
    }
  }, [isStatusDashboard, currentURL]);

  const handleSupportClick = () => {
    window.open("https://proctor.aslai.org/support")
  }

  const handleDashboardClick = () => history.push("/dashboard")
  const handleReportsClick = () => history.push("/reports")
  const handleAdminClick = () => history.push("/admin")

  /***********************
   * RENDER METHODS
   ***********************/

  return (
    <div>
      <Navbar bg="light" expand="true">
        <Navbar.Brand>
          <a href="/" id="logo">
            <img alt="ASLA Logo" className="ms-3 proctor-logo" src="/Bright_ASLA_Logo_Proctor_Assessment_Horizontal.png"></img> | Portal
          </a>
        </Navbar.Brand>
        <Nav className="justify-content-end">
          <Col className="justify-content-end me-3">
            {isAuthenticated &&
              <Button
                className="me-3"
                variant="outline-primary"
                onClick={handleDashboardClick}
              >Live Assessments</Button>
            }
            {isAuthenticated &&
              <Button
                className="me-3"
                variant="outline-primary"
                onClick={handleReportsClick}
              >Reports</Button>
            }
            {isAuthenticated && isAdmin() && (
              <Button
                className="me-3"
                variant="outline-primary"
                onClick={handleAdminClick}
              >Administration</Button>
            )}
            <Button
              className="me-3"
              variant="outline-primary"
              onClick={handleSupportClick}
            >Support</Button>
            {!isAuthenticated && (
              <Button
                className="me-3"
                variant="outline-primary"
                data-testid="login"
                onClick={() =>
                  loginWithRedirect({ redirect_uri: window.location.origin + '/login' })
                }
              >
                Log In
              </Button>
            )}

            {isAuthenticated && <Button data-testid="logout" variant="outline-primary" onClick={() => logout({ returnTo: window.location.origin })}>Log out</Button>}
          </Col>
        </Nav>
      </Navbar>
    </div>
  );
};

export default NavBar;
